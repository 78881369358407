<template>
  <div v-if="advices && advices.data && advices.data.length" class="my-4">
    <div class="row border-bottom">
      <div class="col-6">
        <p class="mb-2 robo-14-500 txt-pri">
          {{ $t("obj_health_records.lbl_advice") }}
        </p>
      </div>
      <div class="col-5">
        <p class="mb-2 robo-14-500 txt-pri">
          {{ $t("obj_health_records.lbl_creation_time") }}
        </p>
      </div>
      <div class="col-1"></div>
    </div>
    <div
      class="row py-2 my-3 border-bottom"
      v-for="advice in advices.data"
      :key="advice.id"
    >
      <div class="col-12 mb-2" v-show="!edit || edit.id != advice.id">
        <div class="row">
          <div class="col-6">
            <p class="mb-2 fs-16 t-141414">{{ advice.content }}</p>
          </div>
          <div class="col-5">
            <div class="d-flex justify-content-between align-items-center pr-3">
              <p class="mb-0 fs-16 t-141414">
                {{ formatHmDMY(advice.created_at) }}
              </p>
            </div>
          </div>
          <div class="col-1">
            <div class="dropdown w-4 h-4 mr-3">
              <img
                src="../../../../public/assets/images/icon/3dot.svg"
                class="cursor-pointer"
                alt
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
              <div
                class="dropdown-menu radius-10 border-0 p-0"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  class="dropdown-item robo-16-400 txt-pri"
                  href="javascript:;"
                  @click="onEdit(advice)"
                >
                  <img
                    src="../../../../public/assets/images/icon/pencil-pri.svg"
                    width="24"
                    alt
                    class="mr-2"
                  />
                  {{ $t("obj_health_records.lbl_edit") }}
                </a>
                <!-- <a
                    class="dropdown-item robo-16-400 txt-pri"
                    href="javascript:;"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#20419B"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                    >
                      <path
                        d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.85 9.14062L18.2 19.2106C18.09 20.7806 18 22.0006 15.21 22.0006H8.79002C6.00002 22.0006 5.91002 20.7806 5.80002 19.2106L5.15002 9.14062"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.33 16.5H13.66"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 12.5H14.5"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Xóa
                </a>-->
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2" v-if="advice.todos">
          <div
            class="d-flex justify-content-start align-items-center mb-2"
            v-for="(todo, idx) in advice.todos"
            :key="todo.id"
          >
            <span class="stt-todo">{{ idx + 1 }}</span>
            <div>
              <p class="mb-1 robo-16-400 t-1F1F1F">{{ todo.content }}</p>
              <div
                class="d-flex justify-content-start align-items-center"
                v-if="todo.frequency_value"
              >
                <img
                  src="../../../../public/assets/images/icon/calendar-blur.svg"
                  width="16"
                  alt
                  class="mr-1"
                />
                <p class="mb-0 robo-12-400 t-5F5F5F">
                  {{
                    todo.frequency_value == 1
                      ? `${$t("obj_health_records.lbl_every")} `
                      : todo.frequency_value + " "
                  }}
                  {{ todo.frequency_unit }}
                  {{
                    todo.frequency_value != 1
                      ? ` ${$t("obj_health_records.lbl_one_time")} `
                      : ""
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-2" v-if="edit && edit.id == advice.id">
        <div class="row">
          <div class="col-12 mb-3">
            <input
              type="text"
              class="form-control border-bottom bg-white radius-0 mb-1"
              :class="!edit.content ? 'border-danger' : 'bd-pri'"
              v-model="edit.content"
              :placeholder="$t('obj_health_records.lbl_enter_content')"
            />
            <span class="robo-12-400 text-danger" v-show="!edit.content">{{
              $t("obj_health_records.lbl_please_enter_content")
            }}</span>
          </div>
          <div
            class="col-12 row my-3"
            v-for="(edit_todo, idx) in edit.todos"
            :key="idx"
          >
            <div class="col-lg-7 mb-2">
              <div class="d-flex justify-content-start align-items-center">
                <span class="stt-todo-edit">{{ idx + 1 }}</span>
                <input
                  type="text"
                  class="form-control bg-white h-100 radius-0"
                  v-model="edit_todo.content"
                  :placeholder="$t('obj_health_records.lbl_enter_to_do_items')"
                />
              </div>
            </div>
            <div class="col-lg-5 mb-1">
              <div
                class="d-flex justify-content-lg-end align-items-center h-100"
              >
                <img
                  src="../../../../public/assets/images/icon/calendar-blur.svg"
                  width="24"
                  alt
                  class="mr-2"
                />
                <span class="robo-16-400 t-blur">
                  {{ $t("obj_health_records.lbl_frequency") }}
                </span>
                <input
                  type="text"
                  class="bg-light input-date text-center"
                  v-model="edit_todo.frequency_value"
                />
                <select v-model="edit_todo.frequency_unit" class="select-unit">
                  <option value="ngày">
                    {{ $t("obj_health_records.lbl_day") }}
                  </option>
                  <option value="tuần">
                    {{ $t("obj_health_records.lbl_week") }}
                  </option>
                  <option value="tháng">
                    {{ $t("obj_health_records.lbl_month") }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div
              class="d-flex cursor-pointer align-items-center h-100"
              @click="addMoreTodo()"
            >
              <img
                src="../../../../public/assets/images/icon/plus-circle-solid.svg"
                alt
              />
              <p class="mb-0 txt-pri robo-14-500">
                {{ $t("obj_health_records.lbl_more_things_to_do") }}
              </p>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-md-end mb-5">
            <button class="btn btn--cancel mr-3" @click="emptyData()">
              {{ $t("obj_health_records.lbl_cancel") }}
            </button>

            <button class="btn bg-pri bd-pri text-white" @click="saveAdvice">
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="loading"
              ></span>
              {{ $t("obj_health_records.lbl_save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <Pagination
      :items="advices"
      :current_page="advices.current_page"
      @onRefresh="onRefresh"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from '../../Pagination.vue'
export default {
  name: 'TodoListTable',
  components: { Pagination },
  props: ['advices', 'person_disease', 'page'],
  data () {
    return {
      edit: null,
      loading: false
    }
  },
  methods: {
    formatHmDMY (dt) {
      return window.moment(dt).format('HH:mm - DD/MM/YYYY')
    },
    onEdit (edit) {
      this.edit = JSON.parse(JSON.stringify(edit))
    },
    blankTodo () {
      return {
        content: '',
        frequency_value: 1,
        frequency_unit: this.$t('obj_health_records.lbl_day')
      }
    },
    addMoreTodo () {
      let blank = this.blankTodo()
      this.edit.todos.push(blank)
    },
    emptyData () {
      this.edit = null
      console.log(this.advices)
    },
    async saveAdvice () {
      let self = this
      let updates = []
      let adds = []
      if (!self.edit) return
      self.loading = true
      if (self.edit.todos) {
        self.edit.todos.forEach((todo) => {
          if (todo.id) {
            if (todo.content) {
              updates.push(todo)
            }
          } else {
            if (todo.content) {
              adds.push(todo)
            }
          }
        })
      }
      try {
        await self.updateAdvice()
        if (updates && updates.length) {
          await self.updateAdviceTodos(self.edit.id, updates)
        }
        if (adds && adds.length) {
          await self.createAdviceTodos(self.edit.id, adds)
        }
      } catch (error) {
        console.log(error)
      } finally {
        self.loading = false
        self.$emit('refresh')
        self.emptyData()
      }
    },
    async updateAdvice () {
      let self = this
      if (self.edit && self.edit.content) {
        let params = {
          content: self.edit.content
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .updateAdvice(self.person_disease?.id, self.edit.id, params)
          .then((resp) => {
            return resp
          })
      }
    },
    async createAdviceTodos (id, data) {
      let self = this
      let params = {
        todos: data
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .createAdviceTodos(id, params)
        .then((resp) => {
          return resp
        })
    },
    async updateAdviceTodos (id, data) {
      let self = this
      let params = {
        todos: data
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .updateAdviceTodos(id, params)
        .then((resp) => {
          return resp
        })
    },
    async deleteAdvice (id) {
      let r = confirm(
        this.$t('obj_health_records.lbl_its_accompanying_actions')
      )
      if (r) {
      }
    },
    onRefresh () {}
  }
}
</script>

<style scoped>
.t-5F5F5F {
  color: #5f5f5f;
}
.t-1F1F1F {
  color: #1f1f1f;
}
.t-141414 {
  color: #141414;
}
.stt-todo {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  border: 1.5px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #595c60;
  margin-right: 10px;
}

.stt-todo-edit {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border: 1.5px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #595c60;
  margin-right: 10px;
}
.radius-0 {
  border-radius: 0;
}
.btn--cancel {
  background-color: #bdbdbd;
  color: #373737;
}
.btn {
  width: 150px;
  height: 48px;
  border-radius: 0;
  border: 0;
}
.input-date {
  width: 37px;
  height: 32px;

  background: #f4f4f4 !important;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 9px;
  border: 0;
  color: #373b3e;
}
.t-blur {
  color: #595c60;
}
.select-unit {
  width: 98px;
  height: 32px;
  left: 46px;
  top: 0px;

  background: #f4f4f4;
  border-radius: 4px;
  border: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #373b3e;
  cursor: pointer;
}
</style>