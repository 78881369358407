<template>
  <thead class="d-none d-md-table bg--primary-corl">
    <tr class="">
      <th class="robo-20-500" style="background-color: #e7eaf5 !important">
        <span class="txt-pri-corl">
          {{ $t("obj_health_records.lbl_uploaded_at") }}
        </span>
      </th>
      <th class="robo-20-500" style="background-color: #e7eaf5 !important">
        <span class="txt-pri-corl">
          {{ $t("obj_health_records.lbl_status") }}
        </span>
      </th>
      <th class="robo-20-500" style="background-color: #e7eaf5 !important">
        <span class="txt-pri-corl ml-sm-1">
          {{ $t("obj_health_records.lbl_test_content") }}
        </span>
      </th>
      <th class="robo-20-500" style="background-color: #e7eaf5 !important">
        <span class="txt-pri-corl ml-sm-1">
          {{ $t("obj_health_records.lbl_test_name") }}
        </span>
      </th>
      <th class="robo-20-500" style="background-color: #e7eaf5 !important">
        <span class="txt-pri-corl ml-sm-1">
          {{ $t("obj_health_records.lbl_action") }}
        </span>
      </th>
      <th class="robo-20-500" style="background-color: #e7eaf5 !important">
        <span class="txt-pri-corl">
          {{ $t("obj_health_records.lbl_updated_by") }}
        </span>
      </th>
    </tr>
  </thead>
</template>
<script>
export default {
  name: 'THeadTableTest',

  data () {
    return {}
  }
}
</script>
<style lang="css" scoped>
</style>