import { render, staticRenderFns } from "./TestResultTable.vue?vue&type=template&id=97c69094&scoped=true"
import script from "./TestResultTable.vue?vue&type=script&lang=js"
export * from "./TestResultTable.vue?vue&type=script&lang=js"
import style0 from "./TestResultTable.vue?vue&type=style&index=0&id=97c69094&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97c69094",
  null
  
)

export default component.exports