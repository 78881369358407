<template>
  <div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-mobile-md card-table table-scroll"
      >
        <THeadTableTest></THeadTableTest>
        <tbody class="h-100" v-if="list_tests && list_tests.count">
          <tr
            v-for="test in list_tests.data"
            :key="test.id"
            class="border-bottom mb-3 mt-3 cursor-pointer"
            :set="(status = getStatus(test))"
          >
            <td data-label="Ngày tải lên" class="table-td-action">
              <span class="txt-pri-corl">{{
                fontmatDate(test.created_date)
              }}</span>
            </td>
            <td data-label="Trạng thái" class="table-td-action">
              <span class="txt-pri-corl" :class="status && status.class">{{
                status && status.name
              }}</span>
            </td>
            <td data-label="Nội dung xét nghiệm" class="table-td-action">
              <span class="txt-pri-corl">{{
                test.test_result_desc || '--'
              }}</span>
            </td>
            <td
              data-label="Tên xét nghiệm"
              class="table-td-name-avatar robo-20-400"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="test.test_result_name"
                placement="top-start"
              >
                <span class="txt-pri-corl ml-2 result-name">{{
                  test.test_result_name
                }}</span>
              </el-tooltip>
            </td>
            <td data-label="Hành động" class="table-td-action">
              <button
                class="btn bg-pri bd-pri text-white mr-2"
                :disabled="!status || status.id !== 2"
                @click="viewDetailTest(test)"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="status && status.id === 2"
                >
                  <path
                    d="M15.5799 12.4999C15.5799 14.4799 13.9799 16.0799 11.9999 16.0799C10.0199 16.0799 8.41992 14.4799 8.41992 12.4999C8.41992 10.5199 10.0199 8.91992 11.9999 8.91992C13.9799 8.91992 15.5799 10.5199 15.5799 12.4999Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.9998 20.7702C15.5298 20.7702 18.8198 18.6902 21.1098 15.0902C22.0098 13.6802 22.0098 11.3102 21.1098 9.90021C18.8198 6.30021 15.5298 4.22021 11.9998 4.22021C8.46984 4.22021 5.17984 6.30021 2.88984 9.90021C1.98984 11.3102 1.98984 13.6802 2.88984 15.0902C5.17984 18.6902 8.46984 20.7702 11.9998 20.7702Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="!status || status.id !== 2"
                >
                  <path
                    d="M14.5299 9.96992L9.46992 15.0299C8.81992 14.3799 8.41992 13.4899 8.41992 12.4999C8.41992 10.5199 10.0199 8.91992 11.9999 8.91992C12.9899 8.91992 13.8799 9.31992 14.5299 9.96992Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.8198 6.26998C16.0698 4.94998 14.0698 4.22998 11.9998 4.22998C8.46984 4.22998 5.17984 6.30998 2.88984 9.90998C1.98984 11.32 1.98984 13.69 2.88984 15.1C3.67984 16.34 4.59984 17.41 5.59984 18.27"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.41992 20.0302C9.55992 20.5102 10.7699 20.7702 11.9999 20.7702C15.5299 20.7702 18.8199 18.6902 21.1099 15.0902C22.0099 13.6802 22.0099 11.3102 21.1099 9.90018C20.7799 9.38018 20.4199 8.89018 20.0499 8.43018"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5104 13.2002C15.2504 14.6102 14.1004 15.7602 12.6904 16.0202"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.47 15.0298L2 22.4998"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.0003 2.5L14.5303 9.97"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {{ $t("obj_health_records.lbl_view") }}
              </button>
            </td>
            <td data-label="Người cập nhật" class="table-td-action">
              <span v-if="test.created_by" class="txt-pri-corl">
                <span v-if="test.created_role == 3">{{
                  test.created_by ? test.created_by.name : ""
                }}</span>
                <span v-if="test.created_role == 2">{{
                  test.created_by && test.created_by.doctor
                    ? test.created_by.doctor.name
                    : ""
                }}</span>
                <span v-if="test.created_role == 1">HODO</span>
              </span>
            </td>
          </tr>
          <!-- <tr>
                        <td colspan="4">
                            <div class="col-sm-12 robo-14-400 text-center">Không có dữ liệu</div>
                        </td>
          </tr>-->
        </tbody>
      </table>
      <Pagination
        v-show="list_tests"
        :items="list_tests"
        :current_page="list_tests.current_page"
        @onRefresh="onRefresh"
      ></Pagination>
    </div>
  </div>
</template>
<script>
import Pagination from '../../components/Pagination'
import THeadTableTest from '../../components/Diseases/THeadTableTest'

export default {
  name: 'TestResultTable',
  props: ['person', 'disease', 'list_tests'],
  components: { Pagination, THeadTableTest },
  data () {
    return {
      tests: null,
      person_id: '',
      medicines_list: null,
      current_page: 1,
      index: null,

      person_test_result: null
    }
  },
  computed: {
    statuses () {
      return [
        {
          id: 1,
          name: this.$t('obj_health_records.lbl_processing'),
          class: 'text-warning'
        },
        {
          id: 2,
          name: this.$t('obj_health_records.lbl_completed'),
          class: 'text-success'
        },
        {
          id: 3,
          name: this.$t('obj_health_records.lbl_unable_to_process_documents'),
          class: 'text-danger'
        }
      ]
    }
  },
  mounted () {
    let self = this
    self.person_id = self.person ? self.person.id : ''
  },
  methods: {
    fontmatDate (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    viewDetailTest (t) {
      let id = t.id
      let type = t.type
      if (type !== 3) {
        let path = {
          path: `/doctor/diseases/${this.$route?.params?.id}/test/${id}`
        }
        if (type === 2) {
          path = {
            path: `/doctor/diseases/${this.$route?.params?.id}/result/${id}`
          }
        }
        this.$router.push(path)
      } else {
        if (t.pac && t.pac.status === 2) {
          this.getUrl(t.pac.accession)
        }
      }
    },
    async getUrl (accession) {
      let self = this
      let params = {
        accession: accession
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getUrlPac(params)
        .then((r) => {
          if (r.data) {
            window.open(r.data)
          }
        })
    },
    onRefresh () {
      this.$emit('onRefresh')
    },
    getStatus (tr) {
      if (tr?.type !== 3) {
        return this.statuses[1]
      } else {
        if (tr.pac) {
          return this.statuses.find((s) => s.id === tr.pac.status)
        } else {
          return this.statuses[0]
        }
      }
    },
    async getPersonDiseaseTestResult () {
      let self = this
      self.current_page = self.$route.query.page
      let params = {
        limit: 10,
        person_diseases_id: self.$route?.params?.id,
        page: self.current_page
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonDiseaseTestResult(params)
        .then((res) => {
          self.list_tests = res.data
          self.current_page = res.data.current_page
          self.last_page = res.data.last_page
        })
    }
  }
}
</script>
<style scoped>
.icon-drop {
  background-image: url("../../../public/assets/images/icon/icon-drop.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center right;
}

.icon-next {
  background-image: url("../../../public/assets/images/icon/icon-next.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center right;
}
.upload-btn {
  height: 100px;
  width: 100px;
  border: dashed 2px #20419b;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-name {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
</style>