<template>
  <div>
    <!-- thêm mới -->
    <div class="row">
      <div class="col-12 my-3">
        <input
          type="text"
          class="form-control border-bottom bg-white radius-0 mb-1"
          :class="!form_add.content ? 'border-danger' : 'bd-pri'"
          v-model="form_add.content"
          :placeholder="$t('obj_health_records.lbl_enter_content')"
        />
        <span class="robo-12-400 text-danger" v-show="!form_add.content">{{
          $t("obj_health_records.lbl_please_enter_content")
        }}</span>
      </div>
      <div class="col-12 row my-3" v-for="(todo, idx) in add_todos" :key="idx">
        <div class="col-lg-7 mb-2">
          <div class="d-flex justify-content-start align-items-center">
            <img
              src="../../../../public/assets/images/icon/trash.svg"
              alt=""
              class="mr-2 cursor-pointer"
              @click="deleteATodo(idx)"
            />
            <span class="stt-todo">{{ idx + 1 }}</span>
            <input
              type="text"
              class="form-control bg-white h-100 radius-0"
              v-model="todo.content"
              :class="!todo.content ? 'border-bottom border-danger' : 'bd-pri'"
              :placeholder="$t('obj_health_records.lbl_enter_to_do_items')"
            />
          </div>
          <span
            class="robo-12-400 text-danger ml-5 pl-3"
            v-show="!todo.content"
            >{{ $t("obj_health_records.lbl_please_enter_content") }}</span
          >
        </div>
        <div class="col-lg-5 mb-1">
          <div class="d-flex justify-content-lg-end align-items-center h-100">
            <img
              src="../../../../public/assets/images/icon/calendar-blur.svg"
              width="24"
              alt=""
              class="mr-2"
            />
            <span class="robo-16-400 t-blur">{{
              $t("obj_health_records.lbl_frequency")
            }}</span>
            <input
              type="text"
              class="bg-light input-date text-center"
              v-model="todo.frequency_value"
            />
            <select v-model="todo.frequency_unit" class="select-unit">
              <option value="ngày">
                {{ $t("obj_health_records.lbl_day") }}
              </option>
              <option value="tuần">
                {{ $t("obj_health_records.lbl_week") }}
              </option>
              <option value="tháng">
                {{ $t("obj_health_records.lbl_month") }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <div
          class="d-flex cursor-pointer align-items-center h-100"
          @click="addMoreTodo()"
        >
          <img
            src="../../../../public/assets/images/icon/plus-circle-solid.svg"
            alt=""
          />
          <p class="mb-0 txt-pri robo-14-500">
            {{ $t("obj_health_records.lbl_more_things_to_do") }}
          </p>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-md-end mb-5">
        <button class="btn btn--cancel mr-3" @click="emptyData()">
          {{ $t("obj_health_records.lbl_cancel") }}
        </button>

        <button class="btn bg-pri bd-pri text-white" @click="submitAdd">
          <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            v-if="loading"
          ></span>
          {{ $t("obj_health_records.lbl_save") }}
        </button>
      </div>
    </div>
    <!-- thêm mới -->
  </div>
</template>

<script>
export default {
  name: 'TodoAdd',
  props: ['person_disease'],
  data () {
    return {
      loading: false,
      form_add: {
        content: ''
      },
      add_todos: []
    }
  },
  methods: {
    addMoreTodo () {
      let blank = this.blankTodo()
      this.add_todos.push(blank)
    },
    deleteATodo (i) {
      this.add_todos.splice(i, 1)
    },
    blankTodo () {
      return {
        content: '',
        frequency_value: 1,
        frequency_unit: this.$t('obj_health_records.lbl_day')
      }
    },
    async submitAdd () {
      let self = this
      if (self.validate() && !self.loading) {
        self.loading = true
        let params = {
          content: self.form_add.content
        }
        try {
          let resp = await self.$rf
            .getRequest('DoctorRequest')
            .createAdvice(self.person_disease?.id, params)
          if (resp) {
            if (self.add_todos && self.add_todos.length) {
              let data_todos = {
                todos: self.add_todos
              }
              let resp_todos = await self.$rf
                .getRequest('DoctorRequest')
                .createAdviceTodos(resp?.data?.id, data_todos)
              if (resp_todos) {
                self.successNoti()
              }
            } else {
              self.successNoti()
            }
          }
        } catch (error) {
        } finally {
          self.loading = false
        }
      }
    },
    successNoti () {
      this.$emit('refresh')
      this.$toast.open({
        message: this.$t('obj_health_records.lbl_added_success'),
        type: 'success'
      })
      this.emptyData()
    },
    emptyData () {
      this.form_add = {
        content: ''
      }
      this.add_todos = [
        {
          content: '',
          frequency_value: 1,

          frequency_unit: this.$t('obj_health_records.lbl_day')
        }
      ]
      this.$emit('onCancelAddNew')
    },
    validate () {
      let self = this
      if (!self.form_add.content) {
        return false
      } else if (self.add_todos && self.add_todos.length) {
        let result = true
        console.log(self.add_todos)
        self.add_todos.forEach((todo) => {
          if (!todo.content) result = false
          if (!todo.frequency_value) todo.frequency_value = 1

          if (!todo.frequency_unit) {
            todo.frequency_unit = this.$t('obj_health_records.lbl_day')
          }
        })
        return result
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.radius-0 {
  border-radius: 0;
}
.btn--cancel {
  background-color: #bdbdbd;
  color: #373737;
}
.btn {
  width: 150px;
  height: 48px;
  border-radius: 0;
  border: 0;
}
.stt-todo {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border: 1.5px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #595c60;
  margin-right: 10px;
}
.input-date {
  width: 37px;
  height: 32px;

  background: #f4f4f4 !important;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 9px;
  border: 0;
  color: #373b3e;
}
.t-blur {
  color: #595c60;
}
.select-unit {
  width: 98px;
  height: 32px;
  left: 46px;
  top: 0px;

  background: #f4f4f4;
  border-radius: 4px;
  border: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #373b3e;
  cursor: pointer;
}
</style>