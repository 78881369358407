var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canChangeSupport() && _vm.can('assign-careteam'))?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-4 mt-1"},[_c('div',{staticClass:"mb-2 d-flex justify-content-between align-items-center"},[_c('p',{staticClass:"robo-16-500 text-gray mb-0"},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_main_doctor"))+" ")])]),_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"btn bg-blue-gray dropdown-toggle w-100 d-flex align-items-center justify-content-start",class:_vm.current_incharge ? 'disabled' : '',attrs:{"href":"#","role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[(_vm.current_incharge || _vm.selected_incharge)?_c('div',{staticClass:"d-flex align-items-center justify-content-start w-100",attrs:{"set":(_vm.doctor = _vm.current_incharge
              ? _vm.current_incharge
              : _vm.selected_incharge
              ? _vm.selected_incharge
              : [])}},[(_vm.doctor && _vm.doctor.user)?_c('span',{staticClass:"mr-3"},[(_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                _vm.getImageURL(_vm.doctor.user.avatar) +
                ')')}):_vm._e(),(!_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.doctor.name ? _vm.doctor.name.charAt(0) : ""))])]):_vm._e()]):_vm._e(),_c('p',{staticClass:"robo-16-500 txt-pri mb-0"},[_vm._v(_vm._s(_vm.doctor.name))])]):_vm._e(),(!_vm.current_incharge && !_vm.selected_incharge)?_c('div',{staticClass:"d-flex align-items-center justify-content-start w-100"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":require("../../../public/assets/images/icon/icon-add-support.svg"),"width":"32","height":"32"}})]):_vm._e()]),_c('div',{staticClass:"dropdown-menu w-100 dropdown-menu-doctor",attrs:{"aria-labelledby":"dropdownMenuLink"}},[_vm._l((_vm.canSelectedInchargeDoctor),function(clinic_doctor){return _c('a',{key:clinic_doctor.id,staticClass:"dropdown-item relative",attrs:{"href":"javascript:;","set":(_vm.doctor = clinic_doctor.doctor)},on:{"click":function($event){return _vm.selectIncharge(clinic_doctor)}}},[_c('span',{staticClass:"d-flex align-items-center justify-content-start flex-wrap",class:_vm.selected_incharge &&
              _vm.selected_incharge.id == clinic_doctor.doctor_id
                ? 'selected-item-doctor'
                : ''},[(_vm.doctor && _vm.doctor.user)?_c('span',{staticClass:"mr-3 d-none d-sm-block"},[(_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                  _vm.getImageURL(_vm.doctor.user.avatar) +
                  ')')}):_vm._e(),(!_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.doctor.name ? _vm.doctor.name.charAt(0) : ""))])]):_vm._e()]):_vm._e(),_c('p',{staticClass:"robo-16-500 txt-pri mb-0"},[_vm._v(_vm._s(_vm.doctor.name))])])])}),(
            !_vm.canSelectedInchargeDoctor || !_vm.canSelectedInchargeDoctor.length
          )?_c('a',{staticClass:"dropdown-item relative",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(_vm.$t("obj_health_records.lbl_empty_list")))]):_vm._e()],2)]),(_vm.err_incharge)?_c('small',{staticClass:"text-red"},[_vm._v("* "+_vm._s(_vm.err_incharge))]):_vm._e()]),_c('div',{staticClass:"col-md-4 mt-1"},[_c('div',{staticClass:"robo-16-500 text-gray mb-2"},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_treat_doctor"))+" ")]),_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"btn bg-blue-gray dropdown-toggle w-100 d-flex align-items-center justify-content-start",attrs:{"href":"#","role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_vm._l((_vm.current_treatings),function(current_treating){return _c('div',{key:current_treating.id + 'currTreating'},[(current_treating)?_c('span',{staticClass:"mr-2"},[(current_treating.user && current_treating.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                  _vm.getImageURL(current_treating.user.avatar) +
                  ')')}):_vm._e(),(!current_treating.user || !current_treating.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(current_treating.name ? current_treating.name.charAt(0) : ""))])]):_vm._e()]):_vm._e()])}),_vm._l((_vm.selected_treatings),function(selected_treating){return _c('div',{key:selected_treating.id + 'sltTreating'},[(selected_treating)?_c('span',{staticClass:"mr-2"},[(selected_treating.user && selected_treating.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                  _vm.getImageURL(selected_treating.user.avatar) +
                  ')')}):_vm._e(),(
                  !selected_treating.user || !selected_treating.user.avatar
                )?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(selected_treating.name ? selected_treating.name.charAt(0) : ""))])]):_vm._e()]):_vm._e()])}),_c('img',{staticClass:"rounded-circle",attrs:{"src":require("../../../public/assets/images/icon/icon-add-support.svg"),"width":"32","height":"32"}})],2)]),_c('div',{staticClass:"dropdown-menu w-100 dropdown-menu-doctor",attrs:{"aria-labelledby":"dropdownMenuLink"}},[_vm._l((_vm.canSelectedTreatingDoctor),function(clinic_doctor){return _c('a',{key:clinic_doctor.id,staticClass:"dropdown-item relative",attrs:{"href":"javascript:;","set":(_vm.doctor = clinic_doctor.doctor)},on:{"click":function($event){return _vm.selectTreating(clinic_doctor)}}},[_c('span',{staticClass:"d-flex align-items-center justify-content-start flex-wrap",class:_vm.getClassTreatingItem(clinic_doctor.doctor)},[(_vm.doctor && _vm.doctor.user)?_c('span',{staticClass:"mr-3 d-none d-sm-block"},[(_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                  _vm.getImageURL(_vm.doctor.user.avatar) +
                  ')')}):_vm._e(),(!_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.doctor.name ? _vm.doctor.name.charAt(0) : ""))])]):_vm._e()]):_vm._e(),_c('p',{staticClass:"robo-16-500 txt-pri mb-0"},[_vm._v(_vm._s(_vm.doctor.name))])])])}),(
            !_vm.canSelectedTreatingDoctor || !_vm.canSelectedTreatingDoctor.length
          )?_c('a',{staticClass:"dropdown-item relative",attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_empty_list")))]):_vm._e()],2)]),(_vm.err_treating)?_c('small',{staticClass:"text-red"},[_vm._v("* "+_vm._s(_vm.err_treating))]):_vm._e()]),_c('div',{staticClass:"col-md-4 mt-1"},[_c('div',{staticClass:"robo-16-500 text-gray mb-2"},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_support_doctor"))+" ")]),_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"btn bg-blue-gray dropdown-toggle w-100 d-flex align-items-center justify-content-start",attrs:{"href":"#","role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_vm._l((_vm.current_supporters),function(current_supporter,i){return _c('div',{key:current_supporter.id + 'currSp' + i},[(current_supporter)?_c('span',{staticClass:"mr-2"},[(current_supporter.user && current_supporter.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                  _vm.getImageURL(current_supporter.user.avatar) +
                  ')')}):_vm._e(),(
                  !current_supporter.user || !current_supporter.user.avatar
                )?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(current_supporter.name ? current_supporter.name.charAt(0) : ""))])]):_vm._e()]):_vm._e()])}),_vm._l((_vm.selected_supporters),function(selected_supporter){return _c('div',{key:selected_supporter.id + 'sltTreating'},[(selected_supporter)?_c('span',{staticClass:"mr-2"},[(
                  selected_supporter.user && selected_supporter.user.avatar
                )?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                  _vm.getImageURL(selected_supporter.user.avatar) +
                  ')')}):_vm._e(),(
                  !selected_supporter.user || !selected_supporter.user.avatar
                )?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(selected_supporter.name ? selected_supporter.name.charAt(0) : ""))])]):_vm._e()]):_vm._e()])}),_c('img',{staticClass:"rounded-circle",attrs:{"src":require("../../../public/assets/images/icon/icon-add-support.svg"),"width":"32","height":"32"}})],2)]),_c('div',{staticClass:"dropdown-menu w-100 dropdown-menu-doctor",attrs:{"aria-labelledby":"dropdownMenuLink"}},[_vm._l((_vm.canSelectedSuporters),function(clinic_doctor){return _c('a',{key:clinic_doctor.id,staticClass:"dropdown-item relative",attrs:{"href":"javascript:;","set":(_vm.doctor = clinic_doctor.doctor)},on:{"click":function($event){return _vm.selectSupporter(clinic_doctor.doctor)}}},[_c('span',{staticClass:"d-flex align-items-center justify-content-start flex-wrap",class:_vm.getClassSupportItem(clinic_doctor.doctor)},[(_vm.doctor && _vm.doctor.user)?_c('span',{staticClass:"mr-3 d-none d-sm-block"},[(_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded",style:('background-image: url(' +
                  _vm.getImageURL(_vm.doctor.user.avatar) +
                  ')')}):_vm._e(),(!_vm.doctor.user.avatar)?_c('span',{staticClass:"avatar avatar-sm avatar-rounded bg-dark text-white"},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.doctor.name ? _vm.doctor.name.charAt(0) : ""))])]):_vm._e()]):_vm._e(),_c('p',{staticClass:"robo-16-500 txt-pri mb-0"},[_vm._v(_vm._s(_vm.doctor.name))])])])}),(!_vm.canSelectedSuporters || !_vm.canSelectedSuporters.length)?_c('a',{staticClass:"dropdown-item relative",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(_vm.$t("obj_health_records.lbl_empty_list")))]):_vm._e()],2)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }