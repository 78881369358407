<template>
  <div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-mobile-md card-table table-scroll"
      >
        <thead class="d-none d-md-table bg--primary-corl">
          <tr class="">
            <th
              class="robo-20-500"
              style="background-color: #e7eaf5 !important"
            >
              <span class="txt-pri-corl ml-sm-1">
                {{ $t("obj_health_records.lbl_test_name") }}
              </span>
            </th>
            <th
              class="robo-20-500"
              style="background-color: #e7eaf5 !important"
            >
              <span class="txt-pri-corl">
                {{ $t("obj_health_records.lbl_uploaded_at") }}
              </span>
            </th>
            <th
              class="robo-20-500"
              style="background-color: #e7eaf5 !important"
            >
              <span class="txt-pri-corl">
                {{ $t("obj_health_records.lbl_updated_by") }}
              </span>
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div>
      <div class="d-flex justify-content-center flex-wrap p-lg-8">
        <div
          class="template-group m-3 d-flex justify-content-center align-items-center"
          @click="onShowCreateResult(true)"
        >
          <!-- <div class="d-flex justify-content-center">
                    <div class="gray-circle"></div>
                </div> -->
          <div align="center" class="px-2">
            <div class="robo-20-500" style="color: #20419b">
              {{ $t("obj_health_records.lbl_diagnose") }}
            </div>
          </div>
        </div>
        <div
          v-for="temp in templates.data"
          class="template-group m-3 d-flex justify-content-center align-items-center"
          @click="selectTemplate(temp)"
          :key="temp.id"
        >
          <!-- <div class="d-flex justify-content-center">
                    <div class="gray-circle"></div>
                </div> -->
          <div align="center" class="px-2">
            <div class="robo-20-500" style="color: #20419b">
              {{ temp.name }}
              <span v-if="temp.template_count"
                >({{ temp.template_count }})</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '../../components/Pagination'
export default {
  name: 'TestTemplateTable',
  props: ['templates'],
  components: { Pagination },
  data () {
    return {
      is_loading: false
    }
  },
  mounted () {
    let self = this
    self.person_id = self.person ? self.person.id : ''
  },
  methods: {
    selectTemplate (temp) {
      this.$emit('selectTemplate', temp)
    },
    onShowCreateResult (show) {
      this.$emit('onShowCreateResult', show)
    }
  }
}
</script>
<style lang="css" scoped>
.template-group {
  height: 166px;
  width: 190px;
  background: #e7eaf5;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  cursor: pointer;
}

.gray-circle {
  height: 82px;
  width: 82px;
  border-radius: 50%;
  background-color: #6c6c6c;
  margin-top: 13px;
  margin-bottom: 16px;
}
</style>