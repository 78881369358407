<template>
  <div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-mobile-md card-table table-scroll"
      >
        <thead class="d-none d-md-table bg--primary-corl">
          <tr class>
            <th
              class="robo-20-500"
              style="background-color: #e7eaf5 !important"
            >
              <span class="txt-pri-corl ml-sm-1">
                {{ $t("obj_health_records.lbl_test_name") }}
              </span>
            </th>
            <th
              class="robo-20-500"
              style="background-color: #e7eaf5 !important"
            >
              <span class="txt-pri-corl">
                {{ $t("obj_health_records.lbl_uploaded_at") }}
              </span>
            </th>
            <th
              class="robo-20-500"
              style="background-color: #e7eaf5 !important"
            >
              <span class="txt-pri-corl">
                {{ $t("obj_health_records.lbl_updated_by") }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody id="invites" class="bg-white">
          <tr style="min-height: 77px">
            <td colspan="4" class="text-center">
              <div class="mt-5">
                <img
                  src="../../../public/assets/images/icon/icon-gallery-add.svg"
                  class="mr-2"
                  style="height: 67px; width: 67px"
                />
              </div>
              <div class="txt-grey-900 mt-4 robo-20-500">
                {{ $t("obj_health_records.lbl_upload_or_enter_normal") }}
              </div>
              <div class="mt-4">
                <button
                  class="btn bg-pri bd-pri text-white btn-opntion-create rounded-0"
                  @click="showUpload(true)"
                >
                  <img
                    src="../../../public/assets/images/icon/upload.svg"
                    class="mr-2"
                    style="height: 20px; width: 20px"
                  />
                  {{ $t("obj_health_records.lbl_upload_file") }}
                </button>
              </div>
              <div class="mt-2">
                <button
                  class="btn bg-pri bd-pri text-white btn-opntion-create rounded-0"
                  @click="showUploadPACS(true)"
                >
                  <img
                    src="../../../public/assets/images/icon/upload.svg"
                    class="mr-2"
                    style="height: 20px; width: 20px"
                  />{{ $t("obj_health_records.lbl_upload_pacs") }}
                </button>
              </div>
              <div class="mt-3">
                <button
                  class="btn bg-pri bd-pri text-white btn-opntion-create rounded-0"
                  @click="onCreate()"
                >
                  <img
                    src="../../../public/assets/images/icon/plus.svg"
                    class="mr-2"
                    style="height: 20px; width: 20px"
                  />
                  {{ $t("obj_health_records.lbl_add_normal") }}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  name: 'TestCreateOptions',
  data () {
    return {
      person_diseases_id: ''
      // person_id: ''
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route.params ? self.$route?.params?.id : ''
    // self.person_id = self.$route.query ? self.$route.query.person : ''
  },
  methods: {
    showUpload (show) {
      this.$emit('onShowModalUpload', show)
    },
    showUploadPACS (show) {
      this.$emit('uploadFilePacs', show)
    },
    onCreate () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/test/create/select-template`
      })
    }
  }
}
</script>
<style scoped>
.btn-opntion-create {
  width: 300px;
}
</style>