<template>
  <div class="row mb-3" v-if="canChangeSupport() && can('assign-careteam')">
    <!-- bác sĩ chính -->
    <div class="col-md-4 mt-1">
      <div class="mb-2 d-flex justify-content-between align-items-center">
        <p class="robo-16-500 text-gray mb-0">
          {{ $t("obj_health_records.lbl_main_doctor") }}
        </p>
      </div>
      <div class="dropdown show">
        <a
          class="btn bg-blue-gray dropdown-toggle w-100 d-flex align-items-center justify-content-start"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          :class="current_incharge ? 'disabled' : ''"
        >
          <!-- item đã chọn -->
          <div
            v-if="current_incharge || selected_incharge"
            class="d-flex align-items-center justify-content-start w-100"
            :set="
              (doctor = current_incharge
                ? current_incharge
                : selected_incharge
                ? selected_incharge
                : [])
            "
          >
            <span v-if="doctor && doctor.user" class="mr-3">
              <span
                class="avatar avatar-sm avatar-rounded"
                v-if="doctor.user.avatar"
                :style="
                  'background-image: url(' +
                  getImageURL(doctor.user.avatar) +
                  ')'
                "
              ></span>
              <span
                v-if="!doctor.user.avatar"
                class="avatar avatar-sm avatar-rounded bg-dark text-white"
              >
                <span style="color: #fff">{{
                  doctor.name ? doctor.name.charAt(0) : ""
                }}</span>
              </span>
            </span>
            <p class="robo-16-500 txt-pri mb-0">{{ doctor.name }}</p>
          </div>
          <!-- chưa chọn item nào -->
          <div
            v-if="!current_incharge && !selected_incharge"
            class="d-flex align-items-center justify-content-start w-100"
          >
            <img
              src="../../../public/assets/images/icon/icon-add-support.svg"
              class="rounded-circle"
              width="32"
              height="32"
            />
          </div>
        </a>
        <!-- dropdown doctor -->
        <div
          class="dropdown-menu w-100 dropdown-menu-doctor"
          aria-labelledby="dropdownMenuLink"
        >
          <a
            class="dropdown-item relative"
            href="javascript:;"
            v-for="clinic_doctor in canSelectedInchargeDoctor"
            @click="selectIncharge(clinic_doctor)"
            :key="clinic_doctor.id"
            :set="(doctor = clinic_doctor.doctor)"
          >
            <span
              class="d-flex align-items-center justify-content-start flex-wrap"
              :class="
                selected_incharge &&
                selected_incharge.id == clinic_doctor.doctor_id
                  ? 'selected-item-doctor'
                  : ''
              "
            >
              <span v-if="doctor && doctor.user" class="mr-3 d-none d-sm-block">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="doctor.user.avatar"
                  :style="
                    'background-image: url(' +
                    getImageURL(doctor.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="!doctor.user.avatar"
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    doctor.name ? doctor.name.charAt(0) : ""
                  }}</span>
                </span>
              </span>
              <p class="robo-16-500 txt-pri mb-0">{{ doctor.name }}</p>
            </span>
          </a>
          <a
            v-if="
              !canSelectedInchargeDoctor || !canSelectedInchargeDoctor.length
            "
            class="dropdown-item relative"
            href="javascript:;"
            >{{ $t("obj_health_records.lbl_empty_list") }}</a
          >
        </div>
      </div>
      <small class="text-red" v-if="err_incharge">* {{ err_incharge }}</small>
    </div>
    <!-- bác sĩ chính -->

    <!-- bác sĩ điều trị -->
    <div class="col-md-4 mt-1">
      <div class="robo-16-500 text-gray mb-2">
        {{ $t("obj_health_records.lbl_treat_doctor") }}
      </div>

      <div class="dropdown show">
        <a
          class="btn bg-blue-gray dropdown-toggle w-100 d-flex align-items-center justify-content-start"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <!-- selected treating -->
          <div class="d-flex align-items-center w-100">
            <div
              v-for="current_treating in current_treatings"
              :key="current_treating.id + 'currTreating'"
            >
              <span v-if="current_treating" class="mr-2">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="current_treating.user && current_treating.user.avatar"
                  :style="
                    'background-image: url(' +
                    getImageURL(current_treating.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="!current_treating.user || !current_treating.user.avatar"
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    current_treating.name ? current_treating.name.charAt(0) : ""
                  }}</span>
                </span>
              </span>
            </div>
            <div
              v-for="selected_treating in selected_treatings"
              :key="selected_treating.id + 'sltTreating'"
            >
              <span v-if="selected_treating" class="mr-2">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="selected_treating.user && selected_treating.user.avatar"
                  :style="
                    'background-image: url(' +
                    getImageURL(selected_treating.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="
                    !selected_treating.user || !selected_treating.user.avatar
                  "
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    selected_treating.name
                      ? selected_treating.name.charAt(0)
                      : ""
                  }}</span>
                </span>
              </span>
            </div>
            <img
              src="../../../public/assets/images/icon/icon-add-support.svg"
              class="rounded-circle"
              width="32"
              height="32"
            />
          </div>
        </a>
        <!-- dropdown doctor -->
        <div
          class="dropdown-menu w-100 dropdown-menu-doctor"
          aria-labelledby="dropdownMenuLink"
        >
          <a
            class="dropdown-item relative"
            href="javascript:;"
            v-for="clinic_doctor in canSelectedTreatingDoctor"
            @click="selectTreating(clinic_doctor)"
            :key="clinic_doctor.id"
            :set="(doctor = clinic_doctor.doctor)"
          >
            <span
              class="d-flex align-items-center justify-content-start flex-wrap"
              :class="getClassTreatingItem(clinic_doctor.doctor)"
            >
              <span v-if="doctor && doctor.user" class="mr-3 d-none d-sm-block">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="doctor.user.avatar"
                  :style="
                    'background-image: url(' +
                    getImageURL(doctor.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="!doctor.user.avatar"
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    doctor.name ? doctor.name.charAt(0) : ""
                  }}</span>
                </span>
              </span>
              <p class="robo-16-500 txt-pri mb-0">{{ doctor.name }}</p>
            </span>
          </a>
          <a
            v-if="
              !canSelectedTreatingDoctor || !canSelectedTreatingDoctor.length
            "
            class="dropdown-item relative"
            href="javascript:;"
          >
            {{ $t("obj_health_records.lbl_empty_list") }}</a
          >
        </div>
      </div>
      <small class="text-red" v-if="err_treating">* {{ err_treating }}</small>
    </div>
    <!-- bác sĩ điều trị -->

    <div class="col-md-4 mt-1">
      <div class="robo-16-500 text-gray mb-2">
        {{ $t("obj_health_records.lbl_support_doctor") }}
      </div>
      <div class="dropdown show">
        <a
          class="btn bg-blue-gray dropdown-toggle w-100 d-flex align-items-center justify-content-start"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <!-- selected sp -->
          <div class="d-flex align-items-center w-100">
            <div
              v-for="(current_supporter, i) in current_supporters"
              :key="current_supporter.id + 'currSp' + i"
            >
              <span v-if="current_supporter" class="mr-2">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="current_supporter.user && current_supporter.user.avatar"
                  :style="
                    'background-image: url(' +
                    getImageURL(current_supporter.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="
                    !current_supporter.user || !current_supporter.user.avatar
                  "
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    current_supporter.name
                      ? current_supporter.name.charAt(0)
                      : ""
                  }}</span>
                </span>
              </span>
            </div>
            <div
              v-for="selected_supporter in selected_supporters"
              :key="selected_supporter.id + 'sltTreating'"
            >
              <span v-if="selected_supporter" class="mr-2">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="
                    selected_supporter.user && selected_supporter.user.avatar
                  "
                  :style="
                    'background-image: url(' +
                    getImageURL(selected_supporter.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="
                    !selected_supporter.user || !selected_supporter.user.avatar
                  "
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    selected_supporter.name
                      ? selected_supporter.name.charAt(0)
                      : ""
                  }}</span>
                </span>
              </span>
            </div>
            <img
              src="../../../public/assets/images/icon/icon-add-support.svg"
              class="rounded-circle"
              width="32"
              height="32"
            />
          </div>
        </a>
        <!-- dropdown doctor -->
        <div
          class="dropdown-menu w-100 dropdown-menu-doctor"
          aria-labelledby="dropdownMenuLink"
        >
          <a
            class="dropdown-item relative"
            href="javascript:;"
            v-for="clinic_doctor in canSelectedSuporters"
            @click="selectSupporter(clinic_doctor.doctor)"
            :key="clinic_doctor.id"
            :set="(doctor = clinic_doctor.doctor)"
          >
            <span
              class="d-flex align-items-center justify-content-start flex-wrap"
              :class="getClassSupportItem(clinic_doctor.doctor)"
            >
              <span v-if="doctor && doctor.user" class="mr-3 d-none d-sm-block">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="doctor.user.avatar"
                  :style="
                    'background-image: url(' +
                    getImageURL(doctor.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="!doctor.user.avatar"
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    doctor.name ? doctor.name.charAt(0) : ""
                  }}</span>
                </span>
              </span>
              <p class="robo-16-500 txt-pri mb-0">{{ doctor.name }}</p>
            </span>
          </a>
          <a
            v-if="!canSelectedSuporters || !canSelectedSuporters.length"
            class="dropdown-item relative"
            href="javascript:;"
            >{{ $t("obj_health_records.lbl_empty_list") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'SelectCareTeam',
  props: [
    'disease',
    'edit',
    'type',
    'owner_clinic_sp',
    'current_incharge',
    'selected_incharge',
    'current_treatings',
    'current_supporters',
    'selected_supporters',
    'selected_treatings',
    'err_incharge',
    'err_treating'
  ],
  data () {
    return {
      open_sl_incharge: false,
      open_sl_treating: false,
      open_sl_support: false,
      doctors: []
    }
  },
  mounted () {
    this.getClinicDoctor()
  },
  computed: {
    canSelectedTreatingDoctor () {
      let self = this
      let doctors = self.doctors?.data
      return doctors?.filter(
        (d) =>
          self.current_incharge?.id !== d.doctor_id &&
          !self.current_treatings?.find((ct) => ct.id === d.doctor_id) &&
          !self.current_supporters?.find((dr) => d.doctor_id === dr.id) &&
          (!d.doctor.type || d.doctor.type === 2)
      )
    },
    canSelectedInchargeDoctor () {
      let self = this
      let doctors = self.doctors?.data
      return doctors?.filter(
        (d) =>
          self.current_incharge?.id !== d.doctor_id &&
          !self.current_treatings?.find((ct) => ct.id === d.doctor_id) &&
          !self.current_supporters?.find((dr) => d.doctor_id === dr.id) &&
          !d.doctor.type
      )
    },
    canSelectedSuporters () {
      let self = this
      let doctors = self.doctors?.data
      return doctors?.filter(
        (d) =>
          self.current_incharge?.id !== d.doctor_id &&
          !self.current_treatings?.find((ct) => ct.id === d.doctor_id) &&
          !self.current_supporters?.find((dr) => d.doctor_id === dr.id)
      )
    }
  },
  methods: {
    async getClinicDoctor () {
      let self = this
      let clinic_id = this.$clinicId
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getClinicDoctor(clinic_id, { verified_doctor: true })
          .then((res) => {
            if (res && res.data && res.data.count) {
              self.doctors = res.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    selectIncharge (clinic_doctor) {
      this.$emit('selectIncharge', clinic_doctor)
    },
    selectTreating (clinic_doctor) {
      this.$emit('selectTreating', clinic_doctor)
    },
    selectSupporter (doctor) {
      this.$emit('selectSupporter', doctor)
    },
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    getClassSupportItem (doctor) {
      let self = this
      let curr = !!self.current_supporters?.find((dr) => dr.id === doctor.id)
      let slt = !!self.selected_supporters?.find((dr) => dr.id === doctor.id)
      if (curr) {
        return 'current-support'
      } else if (slt) {
        return 'selected-item-doctor'
      }
    },
    getClassTreatingItem (doctor) {
      let self = this
      let curr = !!self.current_treatings?.find((dr) => dr.id === doctor.id)
      let slt = !!self.selected_treatings?.find((dr) => dr.id === doctor.id)
      if (curr) {
        return 'current-support'
      } else if (slt) {
        return 'selected-item-doctor'
      }
    },
    canChangeSupport () {
      if (!this.disease) return true
      if (!this.current_incharge && !this.current_treatings) return false
      let user = appUtils.getLocalUser()
      return (
        user.doctor &&
        ((!!this.current_incharge &&
          this.current_incharge.id === user.doctor.id) ||
          (!!this.current_treatings &&
            !!this.current_treatings.find((ct) => user.doctor.id === ct.id)))
      )
    }
  }
}
</script>

<style scoped>
.avatar {
  width: 32px;
  height: 32px;
}
.relative {
  position: relative;
  z-index: 9;
}

.item-container {
  position: absolute;
  background-color: #f2f5fa;
  width: 100%;
  z-index: 10;
}

.item-doctor {
  cursor: pointer;
  position: relative;
}

.item-doctor:hover {
  background-color: white;
}

.current-support:hover {
  background-color: #f2f5fa !important;
  cursor: not-allowed !important;
}

.current-support:after {
  content: "";
  background-image: url("../../../public/assets/images/icon/icon-tick-square.svg");
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 15px;
  position: absolute;
  right: 10px;
  top: 0;
}

.selected-item-doctor:after {
  content: "";
  background-image: url("../../../public/assets/images/icon/icon-tick-square.svg");
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 15px;
  position: absolute;
  right: 10px;
  top: 0;
}
a:hover {
  text-decoration: none;
}
.dropdown-menu-doctor {
  max-height: 50vh;
  overflow: auto;
}
.dropdown-toggle {
  overflow: hidden;
}
</style>