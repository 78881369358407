<template>
  <div>
    <div class="mt-2 mb-3">
      <div class="ml-3 txt-pri robo-20-500 mb-3">{{ $t('lbl_health_index') }}</div>
      <div class>
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table">
            <thead>
              <tr>
                <th class="text-center">{{ $t('lbl_evaluation') }}</th>
                <th>{{ $t('lbl_index_type') }}</th>
                <th class="text-center">{{ $t('lbl_threshold_levels') }}</th>
                <th>{{ $t('lbl_cycle') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(survival_stat, index) in survival_stats" :key="index">
                <td data-label="Theo dõi" class>
                  <input
                    type="checkbox"
                    class="robo-16-500 txt-pri"
                    v-model="survival_stat.is_monitor"
                    value="1"
                  />
                </td>
                <td data-label="Loại chỉ số" class>
                  <div class="row">
                    <div :set="list_stats = getAvailableStats()" class="col-12">
                      <v-select
                        :class="'bg-blue-gray select-stat'"
                        :options="list_stats"
                        label="name"
                        :placeholder="$t('lbl_select_index_type')"
                        :filterable="false"
                        v-model="survival_stat.code"
                        :reduce="stat => stat.code"
                        transition
                        @search="searchStats"
                        @input="(stat) => selectStat(index, stat, list_stats)"
                      >
                        <div slot="no-options">{{ $t('lbl_mornitoring_cate_has_not_add_yet') }}</div>
                        <template #selected-option="{}">
                          <div style="display: flex; align-items: baseline">{{survival_stat.name}}</div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-12 text-center">
                      <small class="text-red">{{getErrStat(survival_stat)}}</small>
                    </div>
                  </div>
                </td>
                <td data-label="Ngưỡng cho phép" class>
                  <div class="row">
                    <div class="col-6">
                      <div class="input-group" v-if="survival_stat.code !== 'height'">
                        <span
                          style="display:flex"
                          class="form-group-text btn bg-blue-gray border-0 robo-16-500 txt-pri flex items-center"
                        >Min</span>
                        <input
                          type="number"
                          step="any"
                          class="form-control robo-16-500 txt-pri"
                          min="0"
                          v-model="survival_stat.min_threshold"
                          @change="survival_stat.error = ''"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group" v-if="survival_stat.code !== 'height'">
                        <span
                          style="display:flex"
                          class="form-group-text btn bg-blue-gray border-0 robo-16-500 txt-pri flex items-center"
                        >Max</span>
                        <input
                          type="number"
                          step="any"
                          class="form-control robo-16-500 txt-pri"
                          min="0"
                          v-model="survival_stat.max_threshold"
                          @change="survival_stat.error = ''"
                        />
                      </div>
                    </div>
                    <div class="col-12 text-center pt-1">
                      <small class="text-red">{{getErrStatsThreshold(survival_stat)}}</small>
                    </div>
                  </div>
                </td>
                <td data-label="Chu kỳ theo dõi" class>
                  <div class="form-group d-flex mb-1">
                    <input
                      type="number"
                      step="any"
                      class="form-control"
                      v-model="survival_stat.cycle"
                      min="1"
                    />
                    <select
                      v-model="survival_stat.cycle_unit"
                      class="border-0 bg-blue-gray robo-16-500 txt-pri"
                    >
                      <option
                        v-for="(cycle_unit,index) in cycle_units"
                        :value="cycle_unit.text"
                        :key="index"
                      >{{$t(`${cycle_unit.text}`)}}</option>
                    </select>
                  </div>
                  <small class="text-red">{{getErrStatsCycle(survival_stat)}}</small>
                </td>
                <td class="text-center">
                  <div class>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      @click="deleteSStat(index)"
                    >
                      <path
                        d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                        stroke="#F9526B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                        stroke="#F9526B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.85 9.14014L18.2 19.2101C18.09 20.7801 18 22.0001 15.21 22.0001H8.79002C6.00002 22.0001 5.91002 20.7801 5.80002 19.2101L5.15002 9.14014"
                        stroke="#F9526B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.33 16.5H13.66"
                        stroke="#F9526B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 12.5H14.5"
                        stroke="#F9526B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="w-100 text-center mt-3 mb-3">
        <button
          type="button"
          class="bg-white txt-pri robo-20-500 border-0 px-5"
          @click="addAStat()"
        >
          <img src="../../../public/assets/images/icon/plus-circle.svg" class="mr-2" />
          {{ $t('lbl_add') }}
        </button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 mb-3">
        <div class="ml-3 txt-pri robo-20-500">{{ $t('lbl_unusual_sign') }}</div>
      </div>
      <div class="row mb-3" v-for="(symptom, index) in curr_symptoms" :key="index">
        <div class="row mb-2">
          <div class="col-md-4 mb-3 pr-3">
            <div class="row">
              <div class="col-md-2 mb-1 d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  class="robo-16-500 txt-pri"
                  :value="1"
                  v-model="symptom.is_monitor"
                />
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control robo-16-500 txt-pri"
                  :placeholder="$t('lbl_enter_unusual_sign')"
                  v-model="symptom.symptom_name"
                />
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-10 mb-1">
                <input
                  type="text"
                  class="form-control robo-16-500 txt-pri"
                  :placeholder="$t('lbl_note_for_unusual_sign')"
                  v-model="symptom.doctor_note"
                />
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 pr-3">
            <div class="row">
              <div class="col-md-1 d-flex align-items-center justify-content-center"></div>
              <div class="col-11">
                <small class="text-red">{{getErrSymptom(symptom)}}</small>
              </div>
            </div>
          </div>
          <div class="col-md-9"></div>
        </div>
      </div>
      <div class="mb-3" v-for="(symptom, index) in symptoms" :key="index+'symp'">
        <div class="row mb-2">
          <div class="col-md-4 mb-3 pr-3">
            <div class="row">
              <div class="col-md-2 mb-1 d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  class="robo-16-500 txt-pri"
                  :value="1"
                  v-model="symptom.is_monitor"
                />
              </div>
              <div class="col-md-10 relative">
                <input
                  type="text"
                  class="form-control robo-16-500 txt-pri"
                  :placeholder="$t('lbl_enter_unusual_sign')"
                  v-model="symptom.symptom_name"
                />
                <small class="text-red absolute -bottom-6 left-4">{{getErrSymptom(symptom)}}</small>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-10 mb-1">
                <input
                  type="text"
                  class="form-control robo-16-500 txt-pri"
                  :placeholder="$t('lbl_note_for_unusual_sign')"
                  v-model="symptom.doctor_note"
                />
              </div>
              <div class="col-md-2 d-flex align-items-center justify-content-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cursor-pointer"
                  @click="deleteSymp(index)"
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="#F9526B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="#F9526B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.85 9.14014L18.2 19.2101C18.09 20.7801 18 22.0001 15.21 22.0001H8.79002C6.00002 22.0001 5.91002 20.7801 5.80002 19.2101L5.15002 9.14014"
                    stroke="#F9526B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.33 16.5H13.66"
                    stroke="#F9526B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="#F9526B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 pr-3">
            <div class="row">
              <div class="col-1 d-flex align-items-center justify-content-center"></div>
              <div class="col-md-11">
                <!-- <small class="text-red">{{getErrSymptom(symptom)}}</small> -->
              </div>
            </div>
          </div>
          <div class="col-9"></div>
        </div>
      </div>
      <div class="w-100 text-center">
        <button
          type="button"
          class="bg-white txt-pri robo-20-500 border-0 px-5"
          @click="addASymptom()"
        >
          <img src="../../../public/assets/images/icon/plus-circle.svg" class="mr-2" />
          {{ $t('lbl_add') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import appUtils from '../../utils/appUtils'
const cycle_units = [
  { id: 2, text: 'lbl_daily' },
  { id: 1, text: 'lbl_hourly' },
  { id: 3, text: 'lbl_weekly' },
  { id: 4, text: 'lbl_monthly' }
]
export default {
  name: 'SettingStats',
  props: ['template', 'current_symptoms', 'current_stats'],
  components: { vSelect },
  data () {
    return {
      loading: false,
      cycle_units,
      survival_stats: [],
      symptoms: [],
      curr_symptoms: [],
      search_stat: '',
      stats: [],
      curr_survival_stats: []
    }
  },
  watch: {
    template: function (new_value, old_value) {
      let self = this
      self.survival_stats = []
      self.symptoms = []
      if (new_value) {
        if (new_value.monitor_stats && new_value.monitor_stats.length) {
          let stats = new_value.monitor_stats
          stats.forEach(function (stat, index) {
            self.survival_stats.push({
              is_monitor: stat.is_monitor,
              min_threshold: stat.min_threshold,
              max_threshold: stat.max_threshold,
              cycle: stat.cycle,
              cycle_unit: stat.cycle_unit,
              code: stat.code,
              name: stat.name || '',
              unit: stat.unit
            })
          })
        }
        if (new_value.symptoms && new_value.symptoms.length) {
          self.symptoms = new_value.symptoms
        }
      }
    },
    current_stats (_new, old) {
      this.survival_stats = _new
    },
    current_symptoms (_new, old) {
      this.curr_symptoms = _new
    },
    survival_stats (_new, old) {
      let self = this
      console.log(_new)
      self.$emit('changeSStats', _new)
    },
    curr_symptoms (_new, old) {
      let self = this
      self.$emit('changeCurrSymp', _new)
    },
    symptoms (_new, old) {
      let self = this
      self.$emit('changeSymp', _new)
    }
  },
  mounted () {
    this.getStats()
  },
  methods: {
    searchStats (txt) {
      this.search_stat = txt
      this.getStats()
    },
    async getStats () {
      let self = this
      self.loading = true
      let clinic_id = this.$clinicId
      try {
        let params = {
          limit: 20,
          sort: 'clinic_id',
          sort_by: 'desc',
          search: '',
          status: 1
        }
        if (self.search_stat) {
          params.search = self.search_stat
        }
        if (!appUtils.isAdmin()) {
          params.clinic_id = clinic_id
        }

        let className = appUtils.isAdmin() ? 'AdminRequest' : 'DoctorRequest'
        await self.$rf
          .getRequest(className)
          .getSurvivalStats(params)
          .then(res => {
            if (res && res.data) {
              self.stats = res.data
            }
          })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    addASymptom () {
      this.symptoms.push({
        is_monitor: true,
        diseases_templates_id: null,
        symptom_name: '',
        doctor_note: ''
      })
    },
    addAStat () {
      this.survival_stats.push({
        code: '',
        name: '',
        is_monitor: true,
        min_threshold: 0,
        max_threshold: 0,
        cycle: 1,
        type_cycle: 'lbl_daily',
        error: '',
        error_cycle: '',
        unit: 'cm'
      })
    },
    getAvailableStats () {
      let self = this
      let stats = self.stats
      if (stats) {
        return stats?.data?.filter(
          s => !self.survival_stats?.find(ss => ss.code === s.code)
        )
      } else {
        return []
      }
    },
    getErrStat (stat) {
      if (!stat.code) {
        return this.$t('lbl_please_select_monitoring_cate')
      }
    },
    getErrStatsThreshold (stats) {
      if (stats.is_monitor) {
        if (stats.min_threshold || stats.max_threshold) {
          if (
            stats.min_threshold === '' ||
            stats.max_threshold === '' ||
            isNaN(stats.min_threshold) ||
            isNaN(stats.max_threshold)
          ) {
            return this.$t('lbl_min_and_max_value_is_required')
          } else if (
            parseFloat(stats.min_threshold) > parseFloat(stats.max_threshold)
          ) {
            return this.$t('lbl_max_value_must_be_greater_than_min_value')
          } else if (
            parseFloat(stats.min_threshold) < 0 ||
            parseFloat(stats.max_threshold) <= 0
          ) {
            return this.$t('lbl_min_and_max_value_must_be_greater_than_0')
          }
        }
      }
    },
    getErrSymptom (symp) {
      if (!symp.symptom_name) {
        return this.$t('lbl_monitoring_cate_name_is_required')
      }
    },
    getErrStatsCycle (stats) {
      if (stats.is_monitor) {
        if (stats.cycle === '' || isNaN(stats.cycle)) {
          return this.$t('lbl_cycle_is_required')
        }
      }
    },
    validateStats () {
      let self = this
      return self.survival_stats.filter(
        stats =>
          stats.is_monitor &&
          stats.stat &&
          (stats.min_threshold === '' ||
            stats.max_threshold === '' ||
            isNaN(stats.min_threshold) ||
            isNaN(stats.max_threshold) ||
            parseFloat(stats.min_threshold) > parseFloat(stats.max_threshold) ||
            parseFloat(stats.min_threshold) < 0 ||
            parseFloat(stats.max_threshold) < 0 ||
            stats.cycle === '' ||
            isNaN(stats.cycle))
      ).length
    },
    validateSymptoms () {
      return this.symptoms.filter(symp => !symp.symptom_name).length
    },
    deleteSStat (i) {
      let r = confirm(this.$t('lbl_confirm_delete'))
      if (!r) return
      this.survival_stats.splice(i, 1)
    },
    deleteSymp (i) {
      let r = confirm(this.$t('lbl_confirm_delete'))
      if (!r) return
      this.symptoms.splice(i, 1)
    },
    selectStat (i, code, list) {
      if (this.survival_stats[i]) {
        let stat = list.find(s => s.code === code)
        if (!stat) return
        this.survival_stats[i].min_threshold = stat.min_threshold || 0
        this.survival_stats[i].max_threshold = stat.max_threshold || 1
        this.survival_stats[i].cycle = stat.cycle || 1
        this.survival_stats[i].cycle_units = stat.cycle_units || 'lbl_daily'
        this.survival_stats[i].name = stat.name || ''
        this.survival_stats[i].unit = stat.unit || ''
      }
    }
  }
}
</script>

<style scoped>
.radius-0 {
  border-radius: 0;
}
.header-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20419b;
  color: white;
  height: 64px;
}
.table-responsive {
  overflow: visible;
}
.a .vs__dropdown-toggle {
  height: 60px !important;
}
</style>